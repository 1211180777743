import { takeLatest, fork } from "redux-saga/effects";
// import JSONfn from "json-fn";

export function* watchRunBackgoundTask() {
	yield takeLatest("RUN_BACKGROUND_TASK_SAGA", runBackgoundTaskSaga);
}

// eslint-disable-next-line require-yield
export function* runBackgoundTaskSaga(action) {
	const { userData, func } = action.data;

	// Rehydrate function

	// func, userData;
	// const rehydrateFunc = JSONfn.parse(func);
	// rehydrateFunc(userData);

	// console.log(userData);
	// const task = yield fork();
}
