import crossDemo from "static/images/resources/cross-filtering/cross-filtering-demo.mp4";
import crossCanna from "static/images/resources/cross-filtering/cross-filtering-canna.mp4";
import crossLevels from "static/images/resources/cross-filtering/cross-filtering-levels.mp4";
import crossTraffic from "static/images/resources/cross-filtering/cross-filtering-traffic.mp4";
import * as React from 'react';
export default {
  crossDemo,
  crossCanna,
  crossLevels,
  crossTraffic,
  React
};