import React from "react";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";

// import { exposeStore, wrapStore } from "redux-in-worker";

import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from "redux-saga";
import reducers from "src/store/reducer";
import saga from "src/services/allSaga";

// eslint-disable-next-line react/prop-types
export default ({ element }) => {
	const middleware = createSagaMiddleware();
	const store = createStore(
		reducers,
		composeWithDevTools(applyMiddleware(middleware))
	);

	// // Expose the store
	// exposeStore(store);

	// // const initialState = ...;
	// const worker = new Worker(store);
	// const wrappedStore = wrapStore(worker, {});

	middleware.run(saga);

	return <Provider store={store}>{element}</Provider>;
};
